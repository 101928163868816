import { Dialog, DialogDismiss } from '@ariakit/react';
import cx from 'classnames';

import buttonStyles from '~/v1/components/button/button.module.scss';

import styles from './modal.module.scss';
import { Icon } from '../icons/icon';
import { IconType } from '../icons/icon.interfaces';

export interface ModalProps {
  children: React.ReactNode;
  isModalOpen: boolean;
  closeModal: () => void;
  className?: string;

  onClose?: () => void;
}

export function Modal({ children, isModalOpen, closeModal, className }: ModalProps) {
  return (
    <Dialog
      modal
      portal
      open={isModalOpen}
      onClose={closeModal}
      className={cx(styles.modal, { [styles.modalOpen]: isModalOpen }, className)}
    >
      <DialogDismiss
        className={cx(
          styles.button,
          buttonStyles.button,
          buttonStyles['button-icon'],
          buttonStyles['button-primary'],
          buttonStyles['button-primary-light'],
        )}
        onClick={closeModal}
      >
        <Icon type={IconType.Close} className={styles.icon} />
      </DialogDismiss>
      {children}
    </Dialog>
  );
}
