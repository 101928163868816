import { forwardRef } from 'react';

import cx from 'classnames';
import YouTube, { type YouTubePlayer, type YouTubeProps } from 'react-youtube';

import styles from './video.module.scss';

interface SpotlightVideoProps extends React.ComponentPropsWithRef<'video'> {
  className?: string;
  videoId?: string;
  captionsUrl?: string;
  isPreview?: boolean;
  isAccordion?: boolean;
  getYouTubeVideoRef?: (ref: YouTubePlayer) => void;
}

function SpotlightVideoRender(
  {
    className,
    captionsUrl,
    isPreview,
    isAccordion,
    autoPlay = true,
    controls = false,
    loop = true,
    muted = true,
    ...props
  }: SpotlightVideoProps,
  ref: React.ForwardedRef<HTMLVideoElement>,
) {
  return (
    <video
      ref={ref}
      className={cx(
        styles.videoWrapper,
        {
          [styles.videoWrapperIsPreview]: isPreview,
          [styles.videoWrapperInAccordion]: isAccordion,
        },
        className,
      )}
      autoPlay={autoPlay}
      controls={controls}
      loop={loop}
      muted={muted}
      crossOrigin="anonymous"
      playsInline={isPreview}
      disablePictureInPicture={isPreview}
      disableRemotePlayback={isPreview}
      {...props}
    >
      {captionsUrl && (
        <track src={captionsUrl} srcLang="en" label="English" kind="captions" default />
      )}
      <track kind="captions" />
    </video>
  );
}

export const SpotlightVideo = forwardRef<HTMLVideoElement, SpotlightVideoProps>(
  SpotlightVideoRender,
);

export function SpotlightYouTubeVideo({
  className,
  videoId,
  isPreview,
  isAccordion,
  autoPlay = true,
  controls = false,
  loop = true,
  muted = true,
  getYouTubeVideoRef,
}: SpotlightVideoProps) {
  const opts: YouTubeProps['opts'] = {
    playerVars: {
      autoplay: autoPlay ? 1 : 0,
      controls: controls ? 1 : 0,
      mute: muted ? 1 : 0,
      loop: loop ? 1 : 0,
      modestbranding: 1,
      showinfo: 0,
      enablejsapi: 1,
      autohide: 1,
      rel: 0,
    },
  };

  return (
    <YouTube
      className={cx(
        styles.videoWrapper,
        {
          [styles.videoWrapperIsPreview]: isPreview,
          [styles.videoWrapperInAccordion]: isAccordion,
        },
        className,
      )}
      videoId={videoId}
      opts={opts}
      onReady={event => getYouTubeVideoRef?.(event.target)}
    />
  );
}
