import { formatISODuration, intervalToDuration } from 'date-fns';

import { type Spotlight } from '~/v1/_types/Spotlight';

import { type SpotlightType } from './spotlight.interface';
import { IconType } from '../icons/icon.interfaces';

export const mapSpotlight = (spotlight: Spotlight): SpotlightType => {
  const buttonLabel = 'ctaLabel' in spotlight ? spotlight.ctaLabel : 'View gallery';
  const buttonIcon = 'videoUrl' in spotlight ? IconType.Play : IconType.Camera;

  const spotlightMapped = {
    headline: spotlight.headline,
    eyebrow: spotlight.subHeadline,
    videoUrl: 'video' in spotlight ? spotlight.video?.src : undefined,
    videoCaptionsUrl: 'videoCaptions' in spotlight ? spotlight.videoCaptions?.src : undefined,
    videoId:
      'videoUrl' in spotlight && spotlight.videoUrl ? getVideoId(spotlight.videoUrl) : undefined,
    previewVideoUrl: 'previewVideo' in spotlight ? spotlight.previewVideo?.src : undefined,
    staticImage:
      'previewStaticImage' in spotlight && spotlight.previewStaticImage
        ? { src: spotlight.previewStaticImage.src, alt: spotlight.previewStaticImage.title }
        : undefined,
    images:
      'images' in spotlight
        ? spotlight.images.map(img => ({
            src: img.src,
            alt: img.title,
            description: img.description,
          }))
        : undefined,
  };

  return {
    ...spotlightMapped,
    buttonIcon,
    buttonLabel,
  };
};

export const getVideoId = (url: string): string => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[7].length == 11 ? match[7] : '';
};

/**
 * @param duration duration in seconds
 */
function parseDuration(duration: number) {
  const parsedDuration = intervalToDuration({
    start: 0,
    end: duration * 1000,
  });
  parsedDuration.seconds = parsedDuration.seconds ? Math.round(parsedDuration.seconds) : undefined;
  return parsedDuration;
}

/**
 * @param duration duration in seconds
 */
export function formatDuration(duration?: number) {
  if (!duration) {
    return '';
  }

  const { hours = 0, minutes = 0, seconds = 0 } = parseDuration(duration);
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = seconds.toString().padStart(2, '0');

  if (hours > 0) {
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  } else {
    return `${formattedMinutes}:${formattedSeconds}`;
  }
}

export function formatAccessibleDuration(duration?: number) {
  if (!duration) {
    return;
  }

  return formatISODuration(parseDuration(duration));
}
